
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IFramePage extends Vue {
  @Prop(String) private src?: string;

  get currentRouteIFrameLink(): string {    
    return this.$route.meta && this.$route.meta.iFrameSrc ? this.$route.meta.iFrameSrc : '';
  }

  get iFrameSrc(): string {
    return this.src || this.currentRouteIFrameLink;
  }
}
